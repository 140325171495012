<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="490px" :before-close="handleClose"
      :close-on-click-modal="false">

      <el-form :model="dialogForm" :rules="dialogRules" ref="dialogForm" class="dialogForm">

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="projectName">
              <el-input v-model="dialogForm.projectName" placeholder="项目名称" ref="projectName"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="needName">
              <el-input v-model="dialogForm.needName" placeholder="需求名称" ref="needName"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="manager">
              <selects @handleChange="handleSelect($event,0)" ref="manager" :options="manager"
                :placeholder="'项目负责人'">
              </selects>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="proDevUi">
              <selects @handleChange="handleSelect($event,1)" ref="proDevUi" :ismultiple="true"
                :options="manager" :placeholder="'UI设计师'">
              </selects>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="proDevFront">
              <selects @handleChange="handleSelect($event,2)" ref="proDevFront" :ismultiple="true"
                :options="manager" :placeholder="'前端'">
              </selects>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="moduleName">
              <el-input v-model="dialogForm.moduleName" ref="moduleName" placeholder="模块名称"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="proVersion">
              <el-input v-model="dialogForm.proVersion" ref="proVersion" placeholder="版本号"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="status">
              <selects @handleChange="handleSelect($event,3)" ref="status" :options="status"
                :placeholder="'状态'">
              </selects>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="createNeed" :loading="isloading">
          {{!isloading ? '确定': '创建中...'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { POST_FINDNEED } from "../../request/api";
import { selects } from "../parts";

export default {
  props: ["Member"],
  data() {
    return {
      isShow: false,
      isloading: false,
      title: "新建需求",

      dialogForm: {
        id: null,
        projectId: null, //项目id
        projectName: "", //项目名称
        needName: "", //需求名称
        manager: "", //负责人
        proDevUi: [], //UI设计师
        proDevFront: [], //前端设计师
        moduleName: "", //模块名称
        proVersion: "", //版本号
        status: null, //状态
      },

      refList: ["manager", "proDevUi", "proDevFront", "status"],
      inputList: ["projectName", "needName", "moduleName", "proVersion"],

      dialogRules: {
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        manager: [
          { required: true, message: "请输入负责人名称", trigger: "change" },
        ],
        needName: [
          { required: true, message: "请输入需求名称", trigger: "blur" },
        ],
        proDevUi: [
          { required: true, message: "请输入UI设计师", trigger: "blur" },
        ],
        proDevFront: [
          { required: true, message: "请输入前端设计师", trigger: "blur" },
        ],
        moduleName: [
          { required: true, message: "请输入模块名称", trigger: "blur" },
        ],
        proVersion: [
          { required: true, message: "请输入版本号", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请编辑需求状态", trigger: "change" },
        ],
      },

      //   负责人
      manager: [],
      //状态
      status: [
        { value: 0, label: "需求分析阶段" },
        { value: 1, label: "UI设计阶段 " },
        { value: 2, label: "编码阶段" },
        { value: 3, label: "上线阶段" },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.manager = this.Member;
    }, 200);
  },
  methods: {
    handleSelect(val, type) {
      if (type == 0) {
        this.dialogForm.manager = val;
      } else if (type == 1) {
        this.dialogForm.proDevUi = val;
      } else if (type == 2) {
        this.dialogForm.proDevFront = val;
      } else {
        this.dialogForm.status = val;
      }
    },
    openNewNeed(id, copy) {
      this.dialogForm.projectId = window.sessionStorage.getItem("currentId");
      if (!this.dialogForm.projectId)
        return this.$notify.error({
          title: "错误",
          message: "请先选择一个项目",
        });
      if (id != -1) {
        POST_FINDNEED({ id })
          .then(({ data: res }) => {
            if (!copy) {
              this.dialogForm.id = res.data.id;
            }
            this.inputList.forEach((element) => {
              //给input输入框赋值
              this.dialogForm[element] = res.data[element]; //给表单赋值
            });
            this.refList.forEach((element) => {
              //给下拉选择框赋值
              this.$refs[element].setValue(res.data[element]); //给组件赋值
              this.dialogForm[element] = res.data[element]; //给表单赋值
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.dialogForm.projectName =
          window.sessionStorage.getItem("currentlabel");
        this.dialogForm.proVersion =
          window.sessionStorage.getItem("currentProVersion");
      }
      this.isShow = true;
    },
    // 关闭确认
    handleClose() {
      this.resetForm();
      this.isShow = false;
    },
    //重置表单
    resetForm() {
      this.dialogForm.id = "";
      this.$refs.dialogForm.resetFields();
      this.refList.forEach((element) => {
        this.$refs[element].resetValue();
      });
    },
    // 创建新任务
    createNeed() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.isloading = true;
          this.$emit("createNeed", this.dialogForm);
          setTimeout(() => {
            this.isloading = false;
          }, 500);
        }
      });
    },
  },
  components: {
    selects,
  },
};
</script>

<style lang="less" scoped>
.logInput {
  width: 200px;
  height: 36px;

  /deep/ .el-input__inner {
    height: 36px;
    border-radius: 0;
    line-height: 36px;
  }
}
</style>