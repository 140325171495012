<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="490px" :before-close="handleClose"
      :close-on-click-modal="false">

      <el-form :model="dialogForm" :rules="dialogRules" ref="dialogForm" class="dialogForm">

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="projectName">
              <el-input v-model="dialogForm.projectName" placeholder="项目名称" ref="projectName"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="apiName">
              <selects @handleChange="handleSelect($event,0)" ref="apiName" :isAllow='true'
                :placeholder="'接口名称(可自定义)'">
              </selects>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="apiModule">
              <selects @handleChange="handleSelect($event,1)" ref="apiModule" :isAllow='true'
                :placeholder="'模块(可自定义)'">
              </selects>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="proDevFront">
              <selects @handleChange="handleSelect($event,2)" ref="proDevFront" :ismultiple="true"
                :options="apiName" :placeholder="'前端'">
              </selects>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="proDevBack">
              <selects @handleChange="handleSelect($event,3)" ref="proDevBack" :ismultiple="true"
                :options="apiName" :placeholder="'后端'">
              </selects>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="proVersion">
              <el-input v-model="dialogForm.proVersion" ref="status" placeholder="版本号"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="apiExtLink">
              <el-input v-model="dialogForm.apiExtLink" ref="status" placeholder="外链"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="status">
              <selects @handleChange="handleSelect($event,4)" ref="status" :options="status"
                :placeholder="'状态'">
              </selects>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="24">
            <el-form-item prop="apiDesc">
              <el-input type="textarea" placeholder="描述" rows="4" v-model="dialogForm.apiDesc"
                maxlength="512" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="createProject" :loading="isloading">
          {{!isloading ? '确定': '创建中...'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { POST_FINDAPIBYID } from "../../request/api";
import { selects } from "../parts";

export default {
  props: ["Member"],
  data() {
    return {
      isShow: false,
      isloading: false,
      title: "新建接口",

      dialogForm: {
        id: null,
        projectName: "", //项目名称
        projectId: null, //项目id
        apiName: "", //接口名称
        apiModule: "", //模块名称
        proDevFront: [], //前端工程师
        proDevBack: [], //后端工程师
        proVersion: "", //版本号
        apiExtLink: "", //外链
        status: null, //状态
        apiDesc: "", //描述
      },

      refList: ["apiName", "apiModule", "proDevFront", "proDevBack", "status"],

      dialogRules: {
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        apiName: [
          { required: true, message: "请输入负责人名称", trigger: "blur" },
        ],
        apiModule: [
          { required: true, message: "请输入模块名称", trigger: "blur" },
        ],
        proDevFront: [
          { required: true, message: "请选择前端工程师", trigger: "blur" },
        ],
        proDevBack: [
          { required: true, message: "请选择后端工程师", trigger: "blur" },
        ],
        proVersion: [
          { required: true, message: "请输入版本号", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请选择开发状态", trigger: "change" },
        ],
        apiDesc: [
          { required: true, message: "请输入基本描述", trigger: "blur" },
        ],
      },

      //   负责人
      apiName: [],
      //状态
      status: [
        { value: 0, label: "未定义" },
        { value: 1, label: "已定义" },
        { value: 2, label: "已实现" },
        { value: 3, label: "已调通" },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.apiName = this.Member;
    }, 200);
  },
  methods: {
    handleSelect(val, type) {
      if (type == 0) {
        this.dialogForm.apiName = val;
      } else if (type == 1) {
        this.dialogForm.apiModule = val;
      } else if (type == 2) {
        this.dialogForm.proDevFront = val;
      } else if (type == 3) {
        this.dialogForm.proDevBack = val;
      } else if (type == 4) {
        this.dialogForm.status = val;
      }
    },
    openNewApi(id, type) {
      this.dialogForm.projectId = window.sessionStorage.getItem("currentId");
      if (!this.dialogForm.projectId)
        return this.$notify.error({
          title: "错误",
          message: "请先选择一个项目",
        });
      if (id != -1) {
        POST_FINDAPIBYID({ id })
          .then(({ data: res }) => {
            //   判断type,若为0则携带id修改数据
            if (type == 0) {
              this.dialogForm.id = res.data.id;
            }
            this.dialogForm.projectName = res.data.projectName;
            this.dialogForm.proVersion = res.data.proVersion;
            this.dialogForm.apiExtLink = res.data.apiExtLink;
            this.dialogForm.apiDesc = res.data.apiDesc;
            this.refList.forEach((element) => {
              this.$refs[element].setValue(res.data[element]); //给组件赋值
              this.dialogForm[element] = res.data[element]; //给表单赋值
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.dialogForm.projectName = window.sessionStorage.getItem(
          "currentlabel"
        );
        this.dialogForm.proVersion = window.sessionStorage.getItem(
          "currentProVersion"
        );
      }
      this.isShow = true;
    },
    // 关闭确认
    handleClose() {
      this.resetForm();
      this.isShow = false;
    },
    //重置表单
    resetForm() {
      this.dialogForm.id = "";
      this.dialogForm.apiName = "";
      this.dialogForm.apiModule = "";
      this.dialogForm.proDevFront = [];
      this.dialogForm.proDevBack = [];
      this.dialogForm.proVersion = "";
      this.$refs.dialogForm.resetFields();
      this.refList.forEach((element) => {
        this.$refs[element].resetValue();
      });
    },
    // 创建新任务
    createProject() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.isloading = true;
          this.$emit("createApi", this.dialogForm);
          setTimeout(() => {
            this.isloading = false;
          }, 500);
        }
      });
    },
  },
  components: {
    selects,
  },
};
</script>

<style lang="less" scoped>
.logInput {
  width: 200px;
  height: 36px;

  /deep/ .el-input__inner {
    height: 36px;
    border-radius: 0;
    line-height: 36px;
  }
}
</style>