<template>
  <div class="container">
    <el-dialog title="项目管理" :visible.sync="isShow" width="770px" :close-on-click-modal="false">

      <el-transfer filterable :titles="['项目列表', '我的项目']" :button-texts="['退出', '加入']"
        filter-placeholder="搜索项目" v-model="value" :data="data" @change="change">
      </el-transfer>

      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="saveDate" :loading="isloding">
          {{isloding ? '保 存...' : '保 存'}}</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { POST_FINDMBPJT, POST_ASSIGNMB } from "../../request/api";
export default {
  data() {
    return {
      isShow: false,
      isloding: false,
      userFrom: {
        memberId: null,
        userName: "",
        projectIds: [],
      },

      data: [], //数据初始化穿梭器里面的值
      value: [], //穿梭器右侧的值
      projectList: [],
    };
  },
  mounted() {
    this.getAllProject();
    this.data = this.generateData();
  },
  methods: {
    //获取所有项目
    getAllProject() {
      this.projectList = this.$store.state.projectName;
    },
    change() {
      this.userFrom.projectIds = this.value;
    },
    generateData() {
      const data = [];
      this.projectList.forEach((project) => {
        data.push({
          key: project.id,
          label: project.label,
        });
      });
      return data;
    },

    open(id, name) {
      this.value = [];
      this.userFrom.memberId = id;
      this.userFrom.userName = name;
      POST_FINDMBPJT({ memberId: id })
        .then(({ data: res }) => {
          let hasProjects = [];
          res.data.hasProjects.map((item) => {
            hasProjects.push(item.projectId);
          });
          this.value = hasProjects;
          this.isShow = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    saveDate() {
      this.isloding = true;
      const waiting = setTimeout(() => {
        this.isloding = false;
        this.isShow = false;
      }, 600);
      POST_ASSIGNMB(this.userFrom)
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.$notify({
              title: "成功",
              message: "项目分配成功",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      waiting;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  /deep/ .el-transfer-panel {
    width: 240px;
  }
  /deep/ .el-input {
    width: 88%;
  }
}
</style>