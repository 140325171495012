<template>
    <div>
        <!-- 开始 -->
        <el-upload ref="uploadImg" list-type="picture-card" :class="{ hide: hideUpload }" :action="domain" :data="QiniuDatas" :limit="limitCount" :headers="QiniuDatas" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess" :on-change="handleChange" :on-remove="handleRemove" :on-error="uploadError">
            <i class="el-icon-plus"></i>

            <!-- <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file, fileList)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>

          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div> -->
        </el-upload>

        <!-- 结束 -->
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
import { POST_QINIUTOKEN } from "../../request/api";

export default {
    props: ["limitCount", "token"],
    data() {
        return {
            fakeToken: "", //用来上传七牛token的假token
            hideUpload: false,
            disabled: false,
            dialogImageUrl: "",
            dialogVisible: false,
            domain: "https://upload.qiniup.com",
            QiniuDatas: {
                key: "",
                token: "",
            },
            fileList: [],
        };
    },
    created() {
        POST_QINIUTOKEN()
            .then(({ data: res }) => {
                this.QiniuDatas.token = res.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    methods: {
        clearFiles() {
            this.$refs.uploadImg.clearFiles();
        },
        // 判断是否隐藏上传框
        isDisableUpload() {
            let fileList = this.$refs.uploadImg.uploadFiles;
            this.hideUpload = fileList.length >= this.limitCount;
        },
        handleChange() {
            this.isDisableUpload();
        },
        handleRemove(file, fileList) {
            console.log(file);
            this.isDisableUpload();
            this.$emit("reSetImgUrl", fileList);
        },
        // 文件上传之前的处理
        beforeAvatarUpload(file) {
            const isPNG = file.type === "image/png";
            const isJPEG = file.type === "image/jpeg";
            const isJPG = file.type === "image/jpg";
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isPNG && !isJPEG && !isJPG) {
                this.$message.error("上传图片只能是 JPG 格式!");
                return false;
            }
            if (!isLt2M) {
                this.$message.error("文件大小不能超过5M");
                return false;
            }
            var index = file.name.lastIndexOf(".");
            //获取后缀
            var ext;
            if (index == -1) {
                ext = "";
            } else {
                ext = "." + file.name.substr(index + 1);
            }
            console.log(ext);
            this.QiniuDatas.key = `oa/upload/${new Date().valueOf()}${
                Math.floor(Math.random() * (9999 - 1000)) + 1000
            }${ext}`; // HMPIC-时间戳+4位随机数-文件名
            // console.log(this.QiniuDatas.key);
        },
        // 上传成功之后的回调
        handleAvatarSuccess() {
            const ImgUrl = this.QiniuDatas.key;
            this.$emit("sendImgUrl", ImgUrl);
            this.$message.success("图片上传成功");
        },
        // 图片预览
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 上传失败
        uploadError(err) {
            console.log(err);
            this.$message.error("上传出错，请重试！" + err.error);
        },
    },
    // watch: {
    //   //通过地址栏获取到查询七牛云的token
    //   async token(newValue) {
    //     const { data: res } = await this.$axios.post(
    //       "http://oa.hearming.com/hm/oa/api/token/qiNiu/getToken",
    //       {},
    //       {
    //         headers: {
    //           token: newValue,
    //         },
    //       }
    //     );
    //     if (res.resCode == 1) {
    //       this.QiniuDatas.token = res.data;
    //     } else if (res.resCode == 10000) {
    //       this.$notify.error({
    //         title: "错误",
    //         message: res.resMsg,
    //       });
    //     }
    //   },
    // },
};
</script>

<style>
.hide .el-upload--picture-card {
    display: none;
}
</style>