<template>
  <div>
    <div class="main" :id="chartName"></div>
  </div>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as $echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { PieChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

// 注册必须的组件
$echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  LegendComponent,
  CanvasRenderer,
]);

export default {
  props: ["projectName","chartName", "title", "chartDate"],
  data() {
    return {
      data: [
        { value: 9, name: "高" },
        { value: 14, name: "中" },
        { value: 30, name: "低" },
      ],
    };
  },
  mounted () {
    this.mycharts();
  },
  methods: {
    mycharts() {
      //   初始化echarts挂载到DOM节点;
      var myChart = $echarts.init(document.getElementById(this.chartName));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: this.projectName ? this.projectName+'/'+this.title : this.title,
          left: "30px",
          top: "30px",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          right: "10px",
          top: "20px",
        },
        label: {
          show: true,
          position: "top",
          formatter: "{c}",
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "70%",
            data: this.chartDate,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      myChart.setOption(option);
    },
  },
  watch: {
    chartDate() {
        this.mycharts();
    }
  },
};
</script>

<style lang="less" scoped>
.main {
  height: 364px;
}
</style>