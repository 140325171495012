<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="490px" :before-close="handleClose"
      :close-on-click-modal="false">

      <el-form :model="dialogForm" :rules="dialogRules" ref="dialogForm" class="dialogForm">

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="proName">
              <el-input v-model="dialogForm.proName" placeholder="项目名称" ref="proName"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="proManager">
              <selects @handleChange="handleSelect($event,0)" ref="proManager" :options="proManager"
                :placeholder="'负责人'">
              </selects>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="proDevUi">
              <selects @handleChange="handleSelect($event,1)" ref="proDevUi" :ismultiple="true"
                :options="proManager" :placeholder="'UI设计师'">
              </selects>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="proDevFront">
              <selects @handleChange="handleSelect($event,2)" ref="proDevFront" :ismultiple="true"
                :options="proManager" :placeholder="'前端'">
              </selects>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="proDevBack">
              <selects @handleChange="handleSelect($event,3)" ref="proDevBack" :ismultiple="true"
                :options="proManager" :placeholder="'后端'">
              </selects>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="proVersion">
              <el-input v-model="dialogForm.proVersion" ref="status" placeholder="版本号"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="status">
              <selects @handleChange="handleSelect($event,4)" ref="status" :options="status"
                :placeholder="'状态'">
              </selects>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="createProject" :loading="isloading">
          {{!isloading ? '确定': '创建中...'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { POST_FINDPRJBYID } from "../../request/api";
import { selects } from "../parts";

export default {
  props: ["Member"],
  data() {
    return {
      isShow: false,
      isloading: false,
      title: "新建项目",

      dialogForm: {
        id: null,
        proName: "", //项目名称
        proManager: "", //负责人
        proDevUi: [], //UI设计师
        proDevFront: [], //前端工程师
        proDevBack: [], //后端工程师
        proVersion: "", //版本号
        status: null, //状态
      },

      refList: [
        "proManager",
        "proDevUi",
        "proDevFront",
        "proDevBack",
        "status",
      ],

      dialogRules: {
        proName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        proManager: [
          { required: true, message: "请输入负责人名称", trigger: "blur" },
        ],
        proDevUi: [
          { required: true, message: "请选择UI设计师", trigger: "blur" },
        ],
        proDevFront: [
          { required: true, message: "请选择前端工程师", trigger: "blur" },
        ],
        proDevBack: [
          { required: true, message: "请选择后端工程师", trigger: "blur" },
        ],
        proVersion: [
          { required: true, message: "请输入版本号", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请选择开发状态", trigger: "change" },
        ],
      },

      //   负责人
      proManager: [],
      //状态
      status: [
        { value: 0, label: "立项" },
        { value: 1, label: "开发中" },
        { value: 2, label: "已上线" },
        { value: 3, label: "延期" },
      ],
    };
  },
  mounted() {
    this.proManager = this.Member;
  },
  methods: {
    handleSelect(val, type) {
      if (type == 0) {
        this.dialogForm.proManager = val;
      } else if (type == 1) {
        this.dialogForm.proDevUi = val;
      } else if (type == 2) {
        this.dialogForm.proDevFront = val;
      } else if (type == 3) {
        this.dialogForm.proDevBack = val;
      } else if (type == 4) {
        this.dialogForm.status = val;
      }
    },
    openNewProject(id) {
      if (id != -1) {
        POST_FINDPRJBYID({ id })
          .then(({ data: res }) => {
            this.dialogForm.id = res.data.id;
            this.dialogForm.proName = res.data.proName;
            this.dialogForm.proVersion = res.data.proVersion;
            this.refList.forEach((element) => {
              this.$refs[element].setValue(res.data[element]); //给组件赋值
              this.dialogForm[element] = res.data[element]; //给表单赋值
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.isShow = true;
    },
    // 关闭确认
    handleClose() {
      this.resetForm();
      this.isShow = false;
    },
    //重置表单
    resetForm() {
      this.dialogForm.proManager = "";
      this.dialogForm.proDevUi = [];
      this.dialogForm.proDevFront = [];
      this.dialogForm.proDevBack = [];
      this.dialogForm.proVersion = "";
      this.$refs.dialogForm.resetFields();
      this.refList.forEach((element) => {
        this.$refs[element].resetValue();
      });
    },
    // 创建新任务
    createProject() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.isloading = true;
          this.$emit("createProject", this.dialogForm);
          setTimeout(() => {
            this.isloading = false;
          }, 500);
        }
      });
    },
  },
  components: {
    selects,
  },
};
</script>

<style lang="less" scoped>
.logInput {
  width: 200px;
  height: 36px;

  /deep/ .el-input__inner {
    height: 36px;
    border-radius: 0;
    line-height: 36px;
  }
}
</style>