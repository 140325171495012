<template>
  <div>
    <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="100px">

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="用户名" prop="userName" :rules="userName">
            <el-input v-model="dynamicValidateForm.userName" placeholder="请输入联系人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="邮箱" prop="email" :rules="email">
            <el-input v-model="dynamicValidateForm.email" placeholder="请输入邮箱"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20" v-for="(domain, index) in dynamicValidateForm.domains" :key="domain.key">
        <el-col :span="8">
          <el-form-item :label="'用户名' + index" :prop="'domains.' + index + '.userName'"
            :rules="userName">
            <el-input v-model="domain.userName" placeholder="请输入联系人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="'邮箱' + index" :prop="'domains.' + index + '.email'"
            :rules="email">
            <el-input v-model="domain.email" placeholder="请输入邮箱"></el-input>
          </el-form-item>
        </el-col>

        <el-button type="text" icon="el-icon-delete-solid" @click.prevent="removeDomain(index)">删除
        </el-button>
        
      </el-row>

      <el-form-item>
        <el-button type="primary" @click="submitForm('dynamicValidateForm')">提交</el-button>
        <el-button type="primary" @click="addDomain" icon="el-icon-user">新增</el-button>
        <el-button @click="resetForm('dynamicValidateForm')">重置</el-button>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dynamicValidateForm: {
        domains: [
          {
            userName: "",
          },
        ],
        userName: "",
        email: "",
      },

      userName: [{ required: true, message: "请输入联系人", trigger: "blur" }],
      email: [
        { required: true, message: "请输入邮箱", trigger: "blur" },
        {
          pattern: /^[\w-]|[0-9]|[\w-]+@[\w-]|[0-9]+\.[\w-]$/, ///^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
          message: "请输入正确的邮箱",
          trigger: "blur",
        },
      ],

    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(index) {
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain() {
      this.dynamicValidateForm.domains.push({
        userName: "",
        email: "",
        key: Date.now(),
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
