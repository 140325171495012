<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="490px" :before-close="handleClose"
      :close-on-click-modal="false">

      <el-form :model="dialogForm" :rules="dialogRules" ref="dialogForm" class="dialogForm">

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="proName">
              <el-input v-model="dialogForm.proName" placeholder="项目名称" ref="proName"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="defSolver">
              <selects @handleChange="handleSelect($event,0)" ref="defSolver" :options="proManager"
                :placeholder="'锅大侠'">
              </selects>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="cateName">
              <el-input v-model="dialogForm.cateName" placeholder="分类名称" ref="cateName"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="defName">
              <el-input v-model="dialogForm.defName" placeholder="缺陷名称" ref="defName"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="proVersion">
              <el-input v-model="dialogForm.proVersion" ref="status" placeholder="版本号"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="defPriority">
              <selects @handleChange="handleSelect($event,1)" ref="defPriority"
                :options="defPriority" :placeholder="'优先级'">
              </selects>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="status">
              <selects @handleChange="handleSelect($event,2)" ref="status" :options="status"
                :placeholder="'状态'">
              </selects>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="proManager">
              <selects @handleChange="handleSelect($event,3)" ref="proManager" :options="proManager"
                :placeholder="'负责人'">
              </selects>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="24">
            <el-form-item prop="defIntroduce">
              <el-input type="textarea" placeholder="描述" rows="4" v-model="dialogForm.defIntroduce"
                maxlength="1024" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="createDefect" :loading="isloading">
          {{!isloading ? '确定': '创建中...'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { POST_FINDDEFECT } from "../../request/api";
import { selects } from "../parts";

export default {
  data() {
    return {
      isShow: false,
      isloading: false,
      title: "新建缺陷",

      dialogForm: {
        projectId: null, //项目id
        id: null, //缺陷id
        proName: "", //项目名称
        cateName: "", //分类名称
        defName: "", //缺陷名称
        defSolver: "", //缺陷修复人（锅大侠）
        proManager: "", //负责人
        proVersion: "", //版本号
        defPriority: 1, //优先级
        status: 0, //状态
        defIntroduce: "", //缺陷说明"
      },

      refList: ["defSolver", "proManager", "defPriority", "status"],
      inputList: [
        "proName",
        "cateName",
        "defName",
        "proVersion",
        "defIntroduce",
      ],

      dialogRules: {
        proName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        proManager: [
          { required: true, message: "请输入负责人名称", trigger: "change" },
        ],
        defSolver: [
          { required: true, message: "请输入缺陷修复人", trigger: "change" },
        ],
        cateName: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
        ],
        defName: [
          { required: true, message: "请输入缺陷名称", trigger: "blur" },
        ],
        proVersion: [
          { required: true, message: "请输入版本号", trigger: "blur" },
        ],
        defPriority: [
          { required: true, message: "请选择优先级", trigger: "change" },
        ],
        status: [
          { required: true, message: "请选择缺陷状态", trigger: "change" },
        ],
        defIntroduce: [
          { required: true, message: "请输入缺陷说明", trigger: "blur" },
        ],
      },

      //   负责人
      proManager: [],
      //优先级
      defPriority: [
        { value: 0, label: "高" },
        { value: 1, label: "中" },
        { value: 2, label: "低" },
      ],
      //状态
      status: [
        { value: 0, label: "打开" },
        { value: 1, label: "关闭" },
        { value: 2, label: "已解决" },
      ],
    };
  },
  mounted() {
    this.proManager = this.$store.state.Member;
  },
  methods: {
    handleSelect(val, type) {
      if (type == 0) {
        this.dialogForm.defSolver = val;
      } else if (type == 1) {
        this.dialogForm.defPriority = val;
      } else if (type == 2) {
        this.dialogForm.status = val;
      } else {
        this.dialogForm.proManager = val;
      }
    },
    openNewDefect(id, copy) {
      this.dialogForm.projectId = window.sessionStorage.getItem("currentId");
      if (!this.dialogForm.projectId)
        return this.$notify.error({
          title: "错误",
          message: "请先选择一个项目",
        });
      if (id != -1) {
        this.title = "编辑缺陷";
        POST_FINDDEFECT({ id })
          .then(({ data: res }) => {
            if (!copy) {
              //如果不是复制，则携带ID
              this.dialogForm.id = res.data.id;
            }
            this.inputList.forEach((element) => {
              //给input输入框赋值
              this.dialogForm[element] = res.data[element]; //给表单赋值
            });
            this.refList.forEach((element) => {
              //给下拉选择框赋值
              this.$refs[element].setValue(res.data[element]); //给组件赋值
              this.dialogForm[element] = res.data[element]; //给表单赋值
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // 间隔一点时间先让组件初始化
        this.title = "新建缺陷";
        setTimeout(() => {
          this.dialogForm.proName =
            window.sessionStorage.getItem("currentlabel");
          this.dialogForm.proVersion =
            window.sessionStorage.getItem("currentProVersion");
          const proManager = window.sessionStorage.getItem("currentProManager");
          this.dialogForm.proManager = proManager;
          this.$refs.defPriority.setValue(1);
          this.$refs.status.setValue(0);
          this.$refs.proManager.setValue(proManager);
        }, 100);
      }
      this.isShow = true;
    },
    // 关闭确认
    handleClose() {
      this.resetForm();
      this.isShow = false;
    },
    //重置表单
    resetForm() {
      this.dialogForm.id = "";
      this.$refs.dialogForm.resetFields();
      this.refList.forEach((element) => {
        this.$refs[element].resetValue();
      });
    },
    // 创建新缺陷
    createDefect() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.isloading = true;
          this.$emit("createDefect", this.dialogForm);
          setTimeout(() => {
            this.isloading = false;
          }, 500);
        }
      });
    },
  },
  components: {
    selects,
  },
};
</script>

<style lang="less" scoped>
.logInput {
  width: 200px;
  height: 36px;

  /deep/ .el-input__inner {
    height: 36px;
    border-radius: 0;
    line-height: 36px;
  }
}
</style>