<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="490px" :before-close="handleClose"
      :close-on-click-modal="false">

      <el-form :model="dialogForm" :rules="dialogRules" ref="dialogForm" class="dialogForm">

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="userName">
              <el-input v-model="dialogForm.userName" placeholder="用户名称" ref="userName"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="mobile">
              <el-input v-model="dialogForm.mobile" placeholder="用户手机号" ref="mobile"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="email">
              <el-input v-model="dialogForm.email" placeholder="用户邮箱" ref="email" class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="department">
              <el-input v-model="dialogForm.department" disabled placeholder="部门" ref="department"
                class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="rolesCode">
              <selects @handleChange="handleSelect($event,0)" ref="rolesCode" :options="rolesCode"
                :placeholder="'所在岗位'">
              </selects>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="createProject" :loading="isloading">
          {{!isloading ? '确定': '创建中...'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { POST_FINDPRJBYID, POST_ADDMEMBER } from "../../request/api";
import { selects } from "../parts";

export default {
  props: ["datedepartment", "daterolesCode"],
  data() {
    return {
      isShow: false,
      isloading: false,
      title: "添加成员",

      dialogForm: {
        id: null,
        userName: "", //用户名
        mobile: "", //手机号
        email: "", //邮箱
        department: "合鸣科技研发中心", //部门
        rolesCode: null, //职位
      },

      dialogRules: {
        userName: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
          {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: /^[\w-]|[0-9]|[\w-]+@[\w-]|[0-9]+\.[\w-]$/,
            message: "请输入正确的邮箱",
            trigger: "blur",
          },
        ],
        department: [
          { required: true, message: "请选择所在部门", trigger: "change" },
        ],
        rolesCode: [
          { required: true, message: "请选择所在岗位", trigger: "change" },
        ],
      },

      //   部门
      department: [],
      //   职位
      rolesCode: [],
      //   人员类型
    };
  },
  mounted() {
    setTimeout(() => {
      this.department = this.datedepartment;
      this.rolesCode = this.daterolesCode;
    }, 200);
  },
  methods: {
    handleSelect(val, type) {
      if (type == 0) {
        this.dialogForm.rolesCode = val;
      }
    },
    openNewUser(id) {
      if (id != -1) {
        POST_FINDPRJBYID({ id })
          .then(({ data: res }) => {
            this.dialogForm.id = res.data.id;
            this.dialogForm.userName = res.data.userName;
            this.dialogForm.mobile = res.data.mobile;
            this.dialogForm.email = res.data.email;
            this.$refs.rolesCode.setValue(res.data.rolesCode); //给组件赋值
            this.dialogForm.rolesCode = res.data.rolesCode; //给表单赋值
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.isShow = true;
    },
    // 关闭确认
    handleClose() {
      this.resetForm();
      this.isShow = false;
    },
    //重置表单
    resetForm() {
      this.$refs.dialogForm.resetFields();
      this.$refs.rolesCode.resetValue();
    },
    // 创建用户
    createProject() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.isloading = true;
          const waiting = setTimeout(() => {
            this.isloading = false;
            this.isShow = false;
            this.resetForm();
          }, 600);
          POST_ADDMEMBER(this.dialogForm)
            .then(({ data: res }) => {
              if (res.resCode == 1) {
                this.$notify.success({
                  title: "成功",
                  message: "用户添加成功",
                });
                this.$util.getMember();
                this.$emit("addUserSuccess");
              }
              waiting;
            })
            .catch((err) => {
              console.log(err);
              waiting;
            });
        }
      });
    },
  },
  components: {
    selects,
  },
};
</script>

<style lang="less" scoped>
.logInput {
  width: 200px;
  height: 36px;

  /deep/ .el-input__inner {
    height: 36px;
    border-radius: 0;
    line-height: 36px;
  }
}
</style>
