<template>
    <div class="selectSearch" @click.stop="">
        <div class="txt" @click="open()">
            <input type="text" placeholder="请选择" v-model="setValue" disabled>
        </div>
        <i :class="isShow?'el-icon-arrow-up is-reverse':'el-icon-arrow-up'"></i>
        <div class="list" v-show="isShow">
            <div class="popper__arrow"></div>
            <div class="search">
                <el-input ref="setValue" v-model="value" placeholder="请输入" size="mini" @blur="onBlur" @input="input"></el-input>
            </div>
            <div class="list-c">
                <div :style="setValue==item.name?'color: #409EFF;':''" class="item" v-for="(item,index) in list" :key="index" @click="selectCheck(item)">{{item.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            setValue: "",
            value: "",
            isShow: false,
            list: [
                {
                    name: "111",
                },
                {
                    name: "222",
                },
                {
                    name: "333",
                },
                {
                    name: "444",
                },
                {
                    name: "555",
                },
                {
                    name: "666",
                },
            ],
        };
    },
    methods: {
        open() {
            this.isShow = !this.isShow;
            if (this.isShow) {
                this.$nextTick(() => {
                    this.$refs.setValue.focus();
                });
            }
        },
        selectCheck(item) {
            console.log(item);
            this.setValue = item.name;
            this.isShow = false;
        },
        onBlur() {
            setTimeout(()=>{
                this.isShow = false;
            },500)
        },
        input(e){
            console.log(e);
        }
    },
};
</script>

<style lang="less" scoped>
.selectSearch {
    position: relative;
    .txt {
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #c0c4cc;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        cursor: pointer;
        padding-right: 30px;
        position: relative;
        input {
            position: absolute;
            left: 15px;
            right: 30px;
            top: 1px;
            bottom: 1px;
            border: 0;
            background: #fff;
            outline: none;
        }
    }
    .el-icon-arrow-up {
        position: absolute;
        color: #c0c4cc;
        font-size: 14px;
        transform: rotateZ(180deg);
        cursor: pointer;
        height: 100%;
        width: 25px;
        text-align: center;
        transition: all 0.3s;
        height: 40px;
        line-height: 40px;
        right: 5px;
    }
    .is-reverse {
        transform: rotateZ(0deg);
    }
    .list {
        position: absolute;
        left: 0;
        right: 0;
        top: 52px;
        background: #fff;
        z-index: 999999;
        border: 1px solid #e4e7ed;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 15px 0;
        .popper__arrow {
            position: absolute;
            top: -6px;
            left: 35px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #fff;
        }
        .search {
            width: 80%;
            margin: 0 auto;
        }
        .list-c {
            max-height: 200px;
            overflow-y: auto;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
            .item {
                font-size: 14px;
                padding: 0 20px;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #606266;
                height: 34px;
                line-height: 34px;
                box-sizing: border-box;
                cursor: pointer;
            }
            .item:hover {
                background-color: #f5f7fa;
            }
        }
        .list-c::-webkit-scrollbar {
            width: 0 !important;
        }
    }
}
</style>